import React from 'react'

import Upcoming from '../../Components/Upcoming/Upcoming'
import Footer from '../../Components/Footer/Footer'

const Event = () => {
  return (
    <>
      <Upcoming />
      <Footer />
    </>
  )
}

export default Event