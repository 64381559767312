import React from 'react'
import Testinomials from '../../Components/Testinomials/Testinomials'
import Footer from '../../Components/Footer/Footer'

const Review = () => {
  return (
    <>
        <Testinomials/>
        <Footer/>
    </>
  )
}

export default Review