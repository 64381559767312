import React from 'react'


import Department from '../../Components/Department/Department';
import Member from '../../Components/Department/Member';
import Footer from '../../Components/Footer/Footer';

const DepartmentMember = () => {

  return (
    <>
    <Department/>
    <Member/>
    <Footer/>
    </>
  )
}

export default DepartmentMember