import React from 'react'
import FAQ from '../../Components/Faq/ClubFaq'
import Footer from '../../Components/Footer/Footer'
const Faq = () => {
  return (
    <>
        <FAQ />
        <Footer/>
    </>
  )
}

export default Faq