import React from 'react'

import Autocar from '../../Components/Autocar/Autocar'
import Footer from '../../Components/Footer/Footer'

const AutocarCompetition = () => {
  return (
    <>
        <Autocar/>
        <Footer/>
    </>
  )
}

export default AutocarCompetition