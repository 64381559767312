import React from 'react';
import About from '../../Components/About/About.jsx';
import Footer from '../../Components/Footer/Footer';

const AboutUs = ({}) => {
  return (
    <>
      <About />
      <Footer />
    </>
  );
};

export default AboutUs;
