import React from 'react';
import './Video.css';
import Video from '../../PopUp/Video/promo10.mp4';

const VideoPlayer = () => {
  return (
    <div>
      <video controls autoPlay className="video-element">
        <source src={Video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
