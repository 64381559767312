import React from 'react'

import Organization from '../../Components/Management/Organization';
import Bureau from '../../Components/Management/Bureau';
import Footer from '../../Components/Footer/Footer';

const OurOrganization = () => {
  return (
    <>
    <Organization/>
    {/* <Bureau/> */}
    <Footer/>
    </>
  )
}

export default OurOrganization