import React from 'react'

import Extraprevious from '../../Components/Event/ExtraPrevious'
import Footer from '../../Components/Footer/Footer'

const ExtraPrevious = () => {
  return (
    <>
        <Extraprevious />
        <Footer />
    </>
  )
}

export default ExtraPrevious