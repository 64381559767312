import React,  { useEffect, useState } from 'react'

import Programming from './Programming'
import Electronic from './Electronic'
import Mechanism from './Mechanism'

// import './eachDepartment.css';

const Member = () => {

  // useEffect(() => {
  //   var elements = document.getElementsByClassName('typewrite');
  //   for (var i = 0; i < elements.length; i++) {
  //     var toRotate = elements[i].getAttribute('data-type');
  //     var period = elements[i].getAttribute('data-period');
  //     if (toRotate) {
  //       new TxtType(elements[i], JSON.parse(toRotate), period);
  //     }
  //   }
  // }, []);

  // function TxtType(el, toRotate, period) {
  //   this.toRotate = toRotate;
  //   this.el = el;
  //   this.loopNum = 0;
  //   this.period = parseInt(period, 10) || 2000;
  //   this.txt = '';
  //   this.tick();
  //   this.isDeleting = false;
  // }

  // TxtType.prototype.tick = function () {
  //   var i = this.loopNum % this.toRotate.length;
  //   var fullTxt = this.toRotate[i];

  //   if (this.isDeleting) {
  //     this.txt = fullTxt.substring(0, this.txt.length - 1);
  //   } else {
  //     this.txt = fullTxt.substring(0, this.txt.length + 1);
  //   }

  //   this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

  //   var that = this;
  //   var delta = 200 - Math.random() * 100;

  //   if (this.isDeleting) {
  //     delta /= 2;
  //   }

  //   if (!this.isDeleting && this.txt === fullTxt) {
  //     delta = this.period;
  //     this.isDeleting = true;
  //   } else if (this.isDeleting && this.txt === '') {
  //     this.isDeleting = false;
  //     this.loopNum++;
  //     delta = 500;
  //   }

  //   setTimeout(function () {
  //     that.tick();
  //   }, delta);
  // };

  return (
    <section id='member'>
        <h5>Who we ARE?</h5>
        <h2>Member's Introduction</h2>

        {/* <div className="under-maintenance">
          <h3>
            <span className="typewrite" data-period="2000" data-type='["Under Maintenance", "Stay Tuned!"]'>
              <span className="wrap"></span>
            </span>
          </h3>
      </div> */}
        <Programming />
        <Mechanism />
        <Electronic />
    </section>
  )
}

export default Member