import Logo2002 from '../Picture/LogoCompetition/Logo2002.jpg';
import Logo2003 from '../Picture/LogoCompetition/Logo2003.jpg';
import Logo2004 from '../Picture/LogoCompetition/Logo2004.jpg';
import Logo2005 from '../Picture/LogoCompetition/Logo2005.jpg';
import Logo2006 from '../Picture/LogoCompetition/Logo2006.jpg';
import Logo2007 from '../Picture/LogoCompetition/Logo2007.jpg';
import Logo2008 from '../Picture/LogoCompetition/Logo2008.jpg';
import Logo2010 from '../Picture/LogoCompetition/Logo2010.jpg';
import Logo2011 from '../Picture/LogoCompetition/Logo2011.jpg';
import Logo2012 from '../Picture/LogoCompetition/Logo2012.jpg';
import Logo2013 from '../Picture/LogoCompetition/Logo2013.jpg';
import Logo2014 from '../Picture/LogoCompetition/Logo2014.jpg';
import Logo2015 from '../Picture/LogoCompetition/Logo2015.jpg';
import Logo2016 from '../Picture/LogoCompetition/Logo2016.jpg';
import Logo2017 from '../Picture/LogoCompetition/Logo2017.jpg';
import Logo2018 from '../Picture/LogoCompetition/Logo2018.jpg';
import Logo2019 from '../Picture/LogoCompetition/Logo2019.jpg';
import Logo2019Urock from '../Picture/LogoCompetition/Logo2019Urock.jpg';
import Logo2021 from '../Picture/LogoCompetition/Logo2021.jpg';
import Logo2022 from '../Picture/LogoCompetition/Logo2022.jpg';
import Logo2023 from '../Picture/LogoCompetition/Logo2023.png';
import Logo2024 from '../Picture/LogoCompetition/Logo2024.png';

import Team02 from '../Picture/TeamPicture/Team02.jpg';
import Team0203 from '../Picture/TeamPicture/Team0203.jpg';
import Team0304 from '../Picture/TeamPicture/Team0304.jpg';
import Team0405 from '../Picture/TeamPicture/Team0405.jpg';
import Team0506 from '../Picture/TeamPicture/Team0506.jpg';
import Team0910 from '../Picture/TeamPicture/Team0910.jpg';
import Team1011 from '../Picture/TeamPicture/Team1011.jpg';
import Team1112 from '../Picture/TeamPicture/Team1112.jpg';
import Team1213 from '../Picture/TeamPicture/Team1213.jpg';
import Team1314 from '../Picture/TeamPicture/Team1314.jpg';
import Team1415 from '../Picture/TeamPicture/Team1415.jpg';
import Team1516 from '../Picture/TeamPicture/Team1516.jpg';
import Team1617 from '../Picture/TeamPicture/Team1617.jpg';
import Team1718 from '../Picture/TeamPicture/Team1718.jpg';
import Team1819 from '../Picture/TeamPicture/Team1819.jpg';
import Team1920 from '../Picture/TeamPicture/Team1920.jpg';
import Team2021 from '../Picture/TeamPicture/Team2021.jpg';
import Team2122 from '../Picture/TeamPicture/Team2122.jpg';
import Team2223 from '../Picture/TeamPicture/Team2223.JPG';
import Team2324 from '../Picture/TeamPicture/Team2324.JPG';


  export const achievementsData = [
      {
        year: 'Robocon 2024',
        logo: Logo2024,
        theme: 'Harvesting Day',
        place: 'Phnom Penh, Cambodia',
        internationalAward: <ul><li>Panasonic  Award</li></ul>,
        teamPicture: Team2324,

      },
      {
        year: 'Robocon 2023',
        logo: Logo2023,
        theme: 'Casting Flower Over Angkor Wat',
        place: 'Phnom Penh, Cambodia',
        domesticAward: <ul><li>Champion</li><li>1st Runner-Up</li><li>Best Technology Award</li><li>Best Engineering Award</li><li>Best Team Award</li></ul>,
        internationalAward: <ul><li>Rohm Award</li></ul>,
        teamPicture: Team2223,
      },
      {
        year: 'Robocon 2022',
        logo: Logo2022,
        theme: 'LAGORI',
        place: 'New Delhi, India',
        domesticAward: <ul><li>Champion</li><li>1st Runner-Up</li><li>Best Technology Award</li></ul>,
        internationalAward: <ul><li>Rohm Special Award</li></ul>,
        teamPicture: Team2122,
      },
      {
        year: 'Robocon 2021',
        logo: Logo2021,
        theme: 'Throwing Arrows into Pots',
        place: 'Jimo, China',
        domesticAward: <ul><li>Champion</li><li>1st Runner-Up</li><li>Best Engineering Award</li><li>Best Design Award</li><li>Best Technology Award</li><li>Best Sustainability Award</li></ul>,
        internationalAward: <ul><li>2nd Runner-Up</li><li>Panasonic Award</li></ul>,
        teamPicture: Team2021,
      },
      {
        year: 'Urock 2019',
        logo: Logo2019Urock,
        theme: 'Legged Robot Race',
        place: 'Perlis, Malaysia',
        domesticAward: <ul><li>Champion</li><li>2nd Runner-Up</li></ul>,
        teamPicture: Team1920,
      },
      {
        year: 'Robocon 2018',
        logo: Logo2018,
        theme: 'NEM CON',
        place: 'Ninh Binh, Vietnam',
        domesticAward: <ul><li>Champion</li><li>1st Runner-Up</li><li>Best Engineering Award</li></ul>,
        internationalAward: <ul><li>Quarterfinalist</li><li>Best Idea Award</li></ul>,
        teamPicture: Team1718,
      },
      {
        year: 'Robocon 2017',
        logo: Logo2017,
        theme: 'The Landing Disc',
        place: 'Tokyo, Japan',
        internationalAward: <ul><li>1st Runner-Up</li></ul>,
        teamPicture: Team1617,
      },
      {
        year: 'Robocon 2016',
        logo: Logo2016,
        theme: 'Clean Energy Recharging the World',
        place: 'Bangkok, Thailand',
        internationalAward: <ul><li>Grand Prix Award (Champion)</li></ul>,
        teamPicture: Team1516,
      },
      {
        year: 'Robocon 2015',
        logo: Logo2015,
        theme: 'Robominton – Badminton Robo Game',
        place: 'Yogyakarta, Indonesia',
        internationalAward: <ul><li>Quaterfinalist</li><li>Best Design Award</li></ul>,
        teamPicture: Team1415,
      },
      {
        year: 'Robocon 2014',
        logo: Logo2014,
        theme: 'A Salute to Parenthood',
        place: 'Pune, India',
        internationalAward: <ul><li>Quaterfinalist</li></ul>,
        teamPicture: Team1314,
      },
      {
        year: 'Robocon 2013',
        logo: Logo2013,
        theme: 'The Green planet',
        place: 'Danang, Vietnam',
        domesticAward: <ul><li>Champion</li><li>1st Runner-Up</li><li>Best Engineering Award</li></ul>,
        internationalAward: <ul><li>Panasonic Award</li></ul>,
        teamPicture: Team1213,
      },
      {
        year: 'Robocon 2012',
        logo: Logo2012,
        theme: 'Peng On Dai Gat, In Pursuit of Peace and Prosperity', 
        place: 'Hong Kong, China',
        domesticAward: <ul><li>Champion</li><li>2nd Runner-Up</li><li>Best Engineering Award</li></ul>,
        internationalAward: <ul><li>Best Engineering Award</li></ul>,
        teamPicture: Team1112,
      },
      {
        year: 'Robocon 2011',
        logo: Logo2011,
        theme: 'Loy Krathong',
        place: 'Bangkok, Thailand',
        domesticAward: <ul><li>Champion</li><li>1st Runner-Up</li><li>Best Engineering Award</li></ul>,
        internationalAward: <ul><li>1st Runner-Up</li></ul>,
        teamPicture: Team1011,
      },  
      {
        year: 'Robocon 2010',
        logo: Logo2010,
        theme: 'Robo-Pharaohs Built Pyramids',
        place: 'Cairo, Egypt',
        domesticAward: <ul><li>Champion</li><li>1st Runner-Up</li><li>Best Technology Award</li></ul>, 
        internationalAward: <ul><li>Best Design Award</li></ul>,
        teamPicture: Team0910,
      },
      {
        year: 'Robocon 2008',
        logo: Logo2008,
        theme: 'Govinda',
        place: 'Pune, India',
        domesticAward: <ul><li>2nd Runner-Up</li></ul>,
      },
      {
        year: 'Robocon 2007',
        logo: Logo2007,
        theme: 'Halong Bay Discovery',
        place: 'Hanoi, Vietnam',
        domesticAward: <ul><li>Champion</li><li>1st Runner-Up</li></ul>, 
        internationalAward: <ul><li>2nd Runner-Up</li><li>Mabuchi Motor Award</li></ul>, 
        
      },
      {
        year: 'Robocon 2006',
        logo: Logo2006,
        theme: 'Building the World’s Tallest Twin Tower',
        place: 'Kuala Lumpur, Malaysia',
        domesticAward: <ul><li>1st Runner-Up</li><li>Best Technology Award</li></ul>,  
        internationalAward: <ul><li>2nd Runner-Up</li><li>Kuala Lumpur Award</li></ul>, 
        teamPicture: Team0506,
      },
      {
        year: 'Robocon 2005',
        logo: Logo2005,
        theme: 'Climb on the Great Wall, Light the Holy Fire',
        place: 'Beijing, China',
        domesticAward: <ul><li>Champion</li><li>1st Runner-Up</li></ul>, 
        internationalAward: <ul><li>Best Idea Award</li></ul>,
        teamPicture: Team0405,
      },
      {
        year: 'Robocon 2004',
        logo: Logo2004,
        theme: 'Reunion of Separated Lovers, Gyeonwoo and Jiknyeo',
        place: 'Seoul, South Korea',
        domesticAward: <ul><li>Champion</li><li>Best Technology Award</li></ul>,
        internationalAward: '',
        teamPicture: Team0304,
      },
      {
        year: 'Robocon 2003',
        logo: Logo2003,
        place: 'Bangkok, Thailand',
        theme: 'Takraw Space Conquero',
        domesticAward: <ul><li>Champion</li><li>Best Technology Award</li></ul> ,
        internationalAward: <ul><li>Toyota Award</li></ul>,
        teamPicture: Team0203,
      },
      {
        year: 'Robocon 2002',
        logo: Logo2002,
        place: 'Tokyo, Japan',
        theme: 'Reach for the Top of Mount Fuji',
        domesticAward: <ul><li>1st Runner-Up</li></ul>,
        internationalAward: '',
        teamPicture: Team02,
      },
    ];
