import React from 'react'

import Memory from '../../Components/Memory/Previous'
import Upcoming from '../../Components/Upcoming/Upcoming'
import Footer from '../../Components/Footer/Footer'

const Event = () => {
  return (
    <>
      <Memory />
      <Upcoming />
      <Footer />
    </>
  )
}

export default Event