import React from 'react'
import Plan from '../../Components/Plan/Plan'
import Footer from '../../Components/Footer/Footer'

const OurPlan = () => {
  return (
    <>
        <Plan/>
        <Footer/>
    </>
  )
}

export default OurPlan