import React from 'react'
import Achievement from '../../Components/Achievement/Achievement.jsx';
import Footer from '../../Components/Footer/Footer.jsx';

const OurAchievement = () => {
  return (
    <>
        <Achievement />
        <Footer />
    </>
  )
}

export default OurAchievement