import React, {useState} from 'react';
import './eachDepartment.css';
import ProgrammingLogo from '../../Picture/LogoClub/Programming.png';
import HeadOfDepartmentImage from '../../Document/Member/2024/meh.png';
import Amir from '../../Document/Member/2024/amir.png';
import Sy from '../../Document/Member/2024/sy.png';
import Saif from '../../Document/Member/2024/saif.png';
import Hassan from '../../Document/Member/2024/hassan.png';
import JX from '../../Document/Member/2024/jx.png';
import Moh from '../../Document/Member/2024/moh.png';
import Labib from '../../Document/Member/2024/labib.png';

import HeadOfDepartmentImage2 from '../../Document/Member/2024/meh2.jpg';
import Amir2 from '../../Document/Member/2024/amir2.jpg';
import Sy2 from '../../Document/Member/2024/sy2.jpg';
import Saif2 from '../../Document/Member/2024/saif2.jpg';
import Hassan2 from '../../Document/Member/2024/hassan2.jpg';
import JX2 from '../../Document/Member/2024/jx2.jpg';
import Moh2 from '../../Document/Member/2024/moh2.jpg';
import Labib2 from '../../Document/Member/2024/labib2.jpg';


const Programming = () => {
    const electronicMembers = [
        {
            name: <h3>Ahmed Amir <br/> Ezaldin Azrag</h3>,
            course: 'Bachelor of Electrical Engineering with Honours',
            year: 'Year 2',
            faculty: 'Faculty of Electrical-Engineering',
            picture: Amir,
            picture2: Amir2,
        },
        {
            name: 'Chen Shu Yan',
            course: 'Bachelor of Computer Science (Software Engineering)',
            year: 'Year 2',
            faculty: 'Faculty of Computing',
            picture: Sy,
            picture2: Sy2,
        },
        {
            name: <h3>Mohamed Saifeldin <br/> Mohamedali Ibrahim</h3> ,
            course: 'Bachelor of Electrical Engineering with Honours',
            year: 'Year 3',
            faculty: 'Faculty of Electrical - Engineering',
            picture: Saif,
            picture2: Saif2,
        },
        {
            name: <h3>Hassan Murtada <br />Hassan Abdalla</h3>,            
            course: 'Bachelor of Electrical Engineering with Honours',
            year: 'Year 2',
            faculty: 'Faculty of Electrical - Engineering',
            picture: Hassan,
            picture2: Hassan2,
        },
        {
            name: 'Wong Jia Xuan',
            course: 'Bachelor of Computer Science (Software Engineering)',
            year: 'Year 2',
            faculty: 'Faculty of Computing',
            picture: JX,
            picture2: JX2,
        },
        {
            name: 'Moh Nian Chun',
            course: 'Bachelor of Engineering(Electrical-Mechatronics) with Honours',
            year: 'Year 2',
            faculty: 'Faculty of Electical-Engineering',
            picture: Moh,
            picture2: Moh2,
        },
        {
            name: <h3>Labib Abdullatif Alsaiad</h3>,            
            course: 'Bachelor of Engineering(Electrical-Mechatronics) with Honours',
            year: 'Year 3',
            faculty: 'Faculty of Electrical - Engineering',
            picture: Labib,
            picture2: Labib2,
        }
    ]

    const [hoveredMember, setHoveredMember] = useState(null);

  return (
    <section id='programming' className="eachDepartment">
        <div className="eachDepartment_head">
            <img src={ProgrammingLogo} alt="Programming Logo" />
        </div>
            <h2>Programming Department</h2>
        
            <div className="head_member">
                <div
                className="headmember_picture"
                onMouseEnter={() => setHoveredMember('head')}
                onMouseLeave={() => setHoveredMember(null)}
                >
                <img
                    src={hoveredMember === 'head' ? HeadOfDepartmentImage2 : HeadOfDepartmentImage}
                    alt="Head of Programming Department: Wong Jen Yang"
                />
                </div>
                <h3>Wong Jen Yang</h3>
                <p className='position'>Head of Department</p>
                <p className='description'>Faculty of Electrical-Engineering</p>
                <p className='description'>Year 3</p>
            </div>

        <div className="prog-member">
        {electronicMembers.map((member, index) => {
          return (
            <div
              className="prog-member_info"
              key={index}
              onMouseEnter={() => setHoveredMember(index)}
              onMouseLeave={() => setHoveredMember(null)}
            >
              <div className="member_picture">
                <img
                  src={hoveredMember === index ? member.picture2 : member.picture}
                  alt={member.name}
                />
              </div>
              <div>
                <h3>{member.name}</h3>
                <p className='position'>Programming Senior</p>
                <p className='description'>{member.faculty}</p>
                <p className='description'>{member.year}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Programming;
