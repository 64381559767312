import React from 'react'

import Contact from '../../Components/Contact/Contact';
import Footer from '../../Components/Footer/Footer';

const ContactUs = () => {
  return (
    <>
    
    <Contact/>
    <Footer/>
    </>
  )
}

export default ContactUs