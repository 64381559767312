import React from 'react'
import Partnership from '../../Components/Partnership/Sponsorship2024'
import Footer from '../../Components/Footer/Footer'

const OurPartnership = () => {
  return (
    <>
      <Partnership />
      <Footer />
    </>
  )
}

export default OurPartnership