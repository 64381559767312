import React from 'react';
import './Sponsorship2024.css';
import { sponsor } from '../../Document/Partnership/Sponsorship2024';
import { BiLogoFacebook, BiLogoInstagram } from 'react-icons/bi';
import { CgWebsite } from 'react-icons/cg';
import { AiOutlineYoutube } from 'react-icons/ai';
import { FaLinkedinIn } from "react-icons/fa";

const Sponsorship2024 = () => {
  const diamondSponsors = sponsor.filter((item) => item.status === 'Diamond');
  const platinumSponsors = sponsor.filter((item) => item.status === 'Platinum');
  const goldSponsors = sponsor.filter((item) => item.status === 'Gold');

  const renderSponsorItem = (item) => {
    const { id, image, name, text, fbacc, facebook, igacc, instagram, youtube, ytacc, website, linkedln, linkedlnacc } = item;
    return (
      <div key={id} className={`sponsor ${item.status.toLowerCase()}`}>
        <div className="sponsor_images">
          <img 
            src={image} 
            alt={name} 
            onError={(e) => { e.target.onerror = null; e.target.src = 'path/to/placeholder-image.png'; }} 
          />
        </div>
        <div className="sponsor_header">
          <h4>{name}</h4>
        </div>
        <div className="sponsor_details">
          <p>{text}</p>
        </div>
        <div className="social_links">
          {facebook && (
            <a href={facebook} className="btn" target="_blank" rel="noopener noreferrer">
              <BiLogoFacebook className='partnership_logo' />
              <div className="tooltiptext">{fbacc}</div>
            </a>
          )}
          {instagram && (
            <a href={instagram} className="btn" target="_blank" rel="noopener noreferrer">
              <BiLogoInstagram className='partnership_logo' />
              <div className='tooltiptext'>{igacc}</div>
            </a>
          )}
          {youtube && (
            <a href={youtube} className="btn" target="_blank" rel="noopener noreferrer">
              <AiOutlineYoutube className='partnership_logo' />
              <div className='tooltiptext'>{ytacc}</div>
            </a>
          )}
          {website && (
            <a href={website} className="btn" target="_blank" rel="noopener noreferrer">
              <CgWebsite className='partnership_logo' />
            </a>
          )}
          {linkedln && (
            <a href={linkedln} className="btn" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn className='partnership_logo' />
              <div className='tooltiptext'>{linkedlnacc}</div>
            </a>
          )}
        </div>
      </div>
    );
  };

  return (
    <section id="partnership" className="top">
      <h5>Our Partners</h5>
      <h2>Partnership</h2>
      <div className="partnership_container">
        <div className="diamond_sponsors">
          <h3>Diamond Sponsors</h3>
          <div className="sponsors_grid">
            {diamondSponsors.map((item) => renderSponsorItem(item))}
          </div>
        </div>
        <div className="platinum_sponsors">
          <h3>Platinum Sponsors</h3>
          <div className="sponsors_grid">
            {platinumSponsors.map((item) => renderSponsorItem(item))}
          </div>
        </div>
        <div className="gold_sponsors">
          <h3>Gold Sponsors</h3>
          <div className="sponsors_grid">
            {goldSponsors.map((item) => renderSponsorItem(item))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sponsorship2024;
