
import MyRobotLogo from '../../Picture/LogoPartnership/MyRobotLogo.png'
import VitroxLogo from '../../Picture/LogoPartnership/VitroxLogo.png'
import CytronLogo from '../../Picture/LogoPartnership/CytronLogo.png'
import IFMLogo from '../../Picture/LogoPartnership/IFMLogo.png'
import SRMLogo from '../../Picture/LogoPartnership/SRMLogo.png'
import StratusAutomationLogo from '../../Picture/LogoPartnership/StratusAutomationLogo.png'
import SolidWorkLogo from '../../Picture/LogoPartnership/SolidWorkLogo.png'
import TSHLogo from '../../Picture/LogoPartnership/TSHLogo.png'
import FineTechLogo from '../../Picture/LogoPartnership/FTPLogo.png'
import MrDIYLogo from '../../Picture/LogoPartnership/MrDIYLogo.png'
import SKLogo from '../../Picture/LogoPartnership/SKLogo.png'
import GreatechLogo from '../../Picture/LogoPartnership/GreatechLogo.png'
import TanerLogo from '../../Picture/LogoPartnership/TanerLogo.png'


import Vitrox from '../../Picture/LogoPartnership/Vitrox.png';
import MyRobot from '../../Picture/LogoPartnership/MyRobot.png';
import Cytron from '../../Picture/LogoPartnership/Cytron.png';
import IFM from '../../Picture/LogoPartnership/IFM.png';
import SRM from '../../Picture/LogoPartnership/SRM.png';
import StratusAutomation from '../../Picture/LogoPartnership/StratusAutomation.png';
import Greatech from '../../Picture/LogoPartnership/Greatech.png';
import SolidWork from '../../Picture/LogoPartnership/SolidWork.png';
import FTP from '../../Picture/LogoPartnership/FTP.png';
import TSH from '../../Picture/LogoPartnership/TSH.png';
import Taner from '../../Picture/LogoPartnership/Taner.png';
import MRDIY from '../../Picture/LogoPartnership/MRDIY.png';

export const sponsor = [
    
  {
    id: 1,
    image: CytronLogo,
    imagefooter: Cytron,
    name: 'Cytron Technologies Sdn Bhd',
    status: '',
    text: 'Founded in 2004, Cytron strives to provide solution for educators and students to build smart electronic & robotic projects. We believe learning digital making is through building projects. We place importance in our team that understand the needs of our customer and are passionate about digital making.',
    website: 'https://www.cytron.io/',
    fbacc: 'Cytron Technologies Malaysia',
    facebook: 'https://www.facebook.com/cytronmy/?mibextid=LQQJ4d',
    ytacc: 'Cytron Technologies',
    youtube: 'https://youtu.be/vnpw75bxoDo',
    igacc: '@cytrontech',
    instagram: 'https://instagram.com/cytrontech?igshid=MzRlODBiNWFlZA==',
  },
  {
    id: 2,
    image: VitroxLogo,
    imagefooter: Vitrox,
    name: 'ViTrox Technologies Sdn Bhd',
    status: 'Diamond',
    text: 'At present, ViTrox is well recognized as one of the world-leading automated machine vision inspection solution providers with an extensive customer base in Malaysia, Singapore, Indonesia, Thailand, Vietnam, Philippines, Taiwan, China, Japan, Korea, India, Australia, Europe, Brazil, Mexico, the USA and more',    
    website: 'https://www.vitrox.com/',
    fbacc: 'ViTrox',
    facebook: 'https://www.facebook.com/ViTroxCorp?mibextid=LQQJ4d',
    ytacc: 'ViTrox Corporation Berhad',
    youtube: 'https://youtu.be/WzpTRH5--Uw',
    igacc: '@vitroxcorp',
    instagram: 'https://instagram.com/vitroxcorp?igshid=MzRlODBiNWFlZA==',
  },
  {
    id: 3,
    image: MyRobotLogo,
    imagefooter: MyRobot,
    name: 'MyRobot',
    status: 'None',
    text: 'My-Robot is the leading educational robotics and coding academy in Malaysia. We are a dedicated company with a mission to provide the latest innovative ways to engage students in Science, Technology, Engineering, and Mathematics (STEM).',
    fbacc: 'My-Robot HQ',
    facebook: 'https://www.facebook.com/myrobot2020/',
    website: 'https://myrobot.com.my/',
  },
  {
    id: 4,
    image: IFMLogo,
    imagefooter: IFM,
    name: 'ifm Malaysia Sdn Bhd',
    status: 'Diamond',
    //text: 'ifm stands for the optimisation and solution of technical processes by means of sensors, networking and control systems. Close customer contact, quality and innovations have made us the market leader in many areas. ifm develops, produces and sells sensors, controllers, software and systems for industrial automation.',
    text: 'Since its foundation in 1969, ifm has developed, produced and sold sensors, controllers, software and systems for industrial automation and for SAP-based solutions for supply chain management and shop floor integration worldwide.',
    website: 'https://www.ifm.com/my/en',
    fbacc: 'ifm Malaysia',
    facebook: 'https://www.facebook.com/ifmMalaysia/',
  },
  {
    id: 5,
    image: SRMLogo,
    imagefooter: SRM,
    name: 'SRM Integration Sdn Bhd',
    status: 'Diamond',
    text: 'Established in June 1996, SRM Integration is one of the world\'s leading suppliers and solutions providers of semiconductors and advanced standard hi-speed test handlers.',
    website: 'https://www.srm.com.my/',
    fbacc: 'SRM Integration',
    facebook: 'https://www.facebook.com/profile.php?id=100063871759393',
  },
  {
    id: 6,
    image: StratusAutomationLogo,
    imagefooter: StratusAutomation,
    name: 'Stratus Automation Sdn Bhd',
    status: 'Diamond',
    text: 'Stratus Automation is one of the leading, most cost-efficient and high-quality cleanroom Automated Material Handling System (AMHS) provider in the world.We provide a complete turnkey AMHS mainly for semiconductor fabs, solar cell, medical industries as well as OEM supplier for many world class semiconductor automation system integrator.',
    website: 'https://stratusauto.com/',
    fbacc: 'Stratus Automation Malaysia',
    facebook: 'https://www.facebook.com/people/Stratus-Automation-Malaysia/100063185860944/',
    linkedlnacc: 'Stratus Automation Sdn. Bhd.',
    linkedln: 'https://www.linkedin.com/company/stratus-automation/',
  },
  {
    id: 7,
    image: SolidWorkLogo,
    imagefooter: SolidWork,
    name: 'SolidWorks',
    status: 'Platinum',
    text: 'SolidWorks is a solid modeling computer-aided design (CAD) and computer-aided engineering (CAE) computer program that runs on Microsoft Windows. SolidWorks is published by Dassault Systèmes.',
    website: 'https://www.solidworks.com/',
    fbacc: 'SolidWorks',
    facebook: 'https://www.facebook.com/solidworks/',
    ytacc: 'SolidWorks',
    youtube: 'https://www.youtube.com/user/SolidWorks',
    igacc: '@solidworks',
    instagram: 'https://www.instagram.com/solidworks/?hl=en',
  },
  {
    id: 8,
    image: TSHLogo,
    imagefooter: TSH,
    name: 'TSH Contract Manufacturing Sdn Bhd',
    status: 'Platinum',
    text: 'Specialized in: Precision Machining, Wire-harnessing, Module assembly & System integration, Quick-turn manufacturing, High mix low to middle volume manufacturing',
    website: 'https://www.tshgroup.com.sg/',
  },
  {
    id: 9,
    image: FineTechLogo,
    imagefooter: FTP,
    name: 'FineTech Precision Sdn Bhd',
    status: 'Platinum',
    text: 'Specialized in: Precision metal sheet fabrication, electrical component assembly, and wiring for panels and switchboards. Focus on delivering high-quality engineering solutions and expertise in precision manufacturing and electrical systems integration.'
  },
  {
    id: 10,
    image: MrDIYLogo,
    imagefooter: MRDIY,
    name: 'Mr DIY',
    status: 'Gold',
    text: 'Mr. D.I.Y. is a Malaysian retail store that sells a wide variety of household products, toys and stationary. The company was founded in 2005 and has since expanded to over 1000 stores across Malaysia, Thailand, Brunei and Indonesia.',
    website: 'https://www.mrdiy.com/',
    fbacc: 'MR DIY',
    facebook: 'https://www.facebook.com/mrdiy2u',
    igacc: '@mrdiy2u',
    instagram: 'https://www.instagram.com/mrdiy2u/',
  },
  {
    id: 11,
    image: SKLogo,
    name: 'SK Silk Screen Sdn Bhd',
    status: 'Silver',
    text: 'SK SILKSCREEN SDN BHD is a one-stop centre offering the complete spectrum of premium items screen printing, heat transfer, embroidery and dye-sublimation– serving direct clients, agencies and other advertising specialty markets.',
    website: 'https://www.sksilkscreen.com/',
    fbacc: 'T-Shirt Printing SK Silkcreen Trading',
    facebook: 'https://www.facebook.com/TShirtPrintingSkSilkcreenTrading/',
  },
  {
    id: 12,
    image: GreatechLogo,
    imagefooter: Greatech,
    name: 'Greatech Technology Berhad',
    status: 'Platinum',
    text: 'With three decades of experience and knowledge in the industry, we are confident in our ability to provide automation solutions in various industries to meet customer productivity challenges, improve return on investment and cost-effectiveness.',
    website: 'https://www.greatech-group.com/',
    fbacc: 'Greatech Technology Berhad',
    facebook: 'https://www.facebook.com/GreatechGroup',
    linkedlnacc: 'Greatech Technology Berhad',
    linkedln: 'https://www.linkedin.com/company/greatech-technology-berhad/',
  },
  {
    id: 13,
    image: TanerLogo,
    imagefooter: Taner,
    name: 'Taner Industrial Technology (M) Sdn Bhd. ',
    status: 'Diamond',
    text: 'We strive to continually outperform ourselves and set new standard for modern way of milling oil palm. We lead process automation and optimization, improve end product quality, reduce wastage and keep cost of production cost low.',
    website: 'https://www.taner.com.my/',
    linkedlnacc: 'Taner Industrial Technology (M) Sdn Bhd',
    linkedln: 'https://www.linkedin.com/company/taner-industrial-technology-m-sdn-bhd/',
  }
]   
